import { BASE_URL } from "../constants";

// Fetch all categories
export async function fetchCategories(token) {
  const response = await fetch(
    `${BASE_URL}/categories/?isSubCategory=true&perPage=9999`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch categories");
  }

  const data = await response.json();
  return data.data;
}

// Create a new category
export async function createNewCategory(
  token,
  { categoryName, subCategories, category_icon }
) {
  const body = {
    name: categoryName,
    sub_categories: subCategories,
    category_icon,
  };

  const response = await fetch(`${BASE_URL}/categories/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to create category");
  }

  return await response.json();
}

// Update an existing category name
export async function updateCategoryName(
  token,
  categoryId,
  categoryName,
  category_icon
) {
  const response = await fetch(`${BASE_URL}/categories/${categoryId}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({ name: categoryName, category_icon }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to update category name");
  }

  return await response.json();
}

// Bulk upsert subcategories
export async function bulkUpsertSubCategories(
  token,
  { categoryId, subCategory }
) {
  const response = await fetch(`${BASE_URL}/sub_categories/bulkUpsert/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
    body: JSON.stringify({ category_id: categoryId, upsert: subCategory }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to update subcategories");
  }

  return await response.json();
}

// Delete a category
export async function deleteCategory(token, categoryId) {
  const response = await fetch(`${BASE_URL}/categories/${categoryId}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });

  if (response.status === 204) {
    return;
  }

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to delete category");
  }

  return await response.json();
}

// Delete a subcategory
export async function deleteSubCategory(token, subCategoryId) {
  const response = await fetch(
    `${BASE_URL}/subCategory/remove/${subCategoryId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );
  console.log(response.status);
  if (response.status === 204) {
    return;
  }

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData || "Failed to delete subcategory");
  }
}
